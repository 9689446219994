import "core-js/stable";
import "regenerator-runtime/runtime";
import throttle from "lodash/throttle";

import { TweenMax, ScrollToPlugin } from "gsap/all";
import { createApp } from "vue";

// Hero
const hero = async () => {
    // Create our vue instance
    const heroVue = createApp({
        compilerOptions: {
            delimiters: ["${", "}"],
        },
        components: {},
        data: () => ({
            isVideo: false,
            isPaused: false,
            videoWidth: 100,
        }),
        computed: {},
        methods: {
            jumpDown: function () {
                //let h = this.windowSize().height;
                let h = this.elementSize(document.getElementById("hero")).height;

                TweenMax.to(window, 1, { scrollTo: { y: h, x: 0 } });
            },

            isMobile: function () {
                if (window.matchMedia("(min-width: 1200px)").matches) {
                    return false;
                }

                return true;
            },

            togglePlay: function () {
                this.isPaused = !this.isPaused;
            },

            windowSize: function () {
                let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

                return {
                    width: w,
                    height: h,
                };
            },

            elementSize: function (e) {
                let rect = e.getBoundingClientRect();

                return {
                    width: rect.width,
                    height: rect.height,
                };
            },

            throttleResize: throttle(function () {
                console.log("Window Resized");
                let vidAR = document.getElementById("hero-video-ar");
                let ws = this.windowSize();
                let ars = this.elementSize(vidAR);
                let windowAR = ws.height / ws.width;
                let videoAR = ars.height / ars.width;

                if (windowAR > videoAR) {
                    this.videoWidth = (windowAR / videoAR) * 100;
                } else {
                    this.videoWidth = 100;
                }

                console.log("Updated Video Width: " + this.videoWidth + "%");

                if (this.isMobile()) {
                    document.getElementById("hero").classList.add("no-video");
                } else {
                    document.getElementById("hero").classList.remove("no-video");
                }
            }, 100),

            throttleScroll: throttle(function () {
                console.log("Window Scrolled");
            }, 100),

            init: function () {
                let _this = this;
                let h = document.getElementById("hero");
                let vid = document.getElementById("hero-video");

                console.log("Init Hero -> Setting Things Up");

                // 1. check if video
                if (vid) {
                    // 2. setup listeners for scroll.
                    window.addEventListener("scroll", this.throttleScroll);
                    // 3. setup listender for resize
                    window.addEventListener("resize", this.throttleResize);

                    this.throttleResize();
                } else {
                    console.log("No Video in Hero");
                }
            },
        },
        mounted() {
            console.log("Hero VUE is Ready, ok");
            this.$nextTick(function () {
                this.init();
            });
        },
    });

    const root = heroVue.mount("#hero");
    return root;
};
// Execute async function
hero()
    .then((root) => {
        console.log("Vue Hero Component Mounted");
    })
    .catch((error) => {
        console.error("Error Vue Hero Component", error);
    });

export default hero;
